<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div
        class="modal-content"
        style="min-height: 590px;"
      >
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('button.detail') }} {{ $t('module.reservation') }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="clearData()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3 class="kt-heading kt-heading--md kt-heading--no-top-margin">{{ $t('reservation.patientInfo') }}</h3>
          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
            <div class="kt-list">
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.name') }}</span>
                <span class="kt-list__text col-md-7">{{ reservasi.namaPasien }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.dob') }}</span>
                <span class="kt-list__text col-md-7">{{ reservasi.tanggalLahir | formatDate }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.gender') }}</span>
                <span class="kt-list__text col-md-7"> {{ ( reservasi.gender) ?  reservasi.gender.name : '' }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.phoneNo') }}</span>
                <span class="kt-list__text col-md-7">{{ reservasi.telp | formatTelepon }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.email') }}</span>
                <span class="kt-list__text col-md-7">{{ reservasi.email }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.guarantor') }}</span>
                <span class="kt-list__text col-md-7">{{ (reservasi.jenisPenjamin) ? reservasi.jenisPenjamin.nama : '' }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.medicalNo') }}</span>
                <span class="kt-list__text col-md-7">{{ reservasi.nomorMedicalRecord }}</span>
              </div>
            </div>
          </div>
          <div class="kt-separator kt-separator--space-sm kt-separator--border-dashed"></div>
          <h3 class="kt-heading kt-heading--md kt-heading--no-top-margin">{{ $t('module.reservation') }}</h3>
          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
            <div class="kt-list">
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.doctor') }}</span>
                <span class="kt-list__text col-md-7">{{ namaDokter }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.poly') }}</span>
                <span class="kt-list__text col-md-7">{{ namaPoli }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.schedule') }}</span>
                <span class="kt-list__text col-md-7">{{ reservasi.tanggalKunjungan | formatSchedule(reservasi.estimasiWaktu) }}</span>
              </div>
              <div
                class="kt-list__item"
                v-if="reservasi.nomorAntrian"
              >
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.queueNo') }}</span>
                <span class="kt-list__text col-md-7">{{ reservasi.nomorAntrian }}</span>
              </div>
              <div class="kt-list__item">
                <span class="kt-list__text col-md-5 text-muted">{{ $t('common.message') }}</span>
                <span class="kt-list__text col-md-7">{{ reservasi.pesan }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Reservasi from "../../../model/reservasi-model";

export default {
  data() {
    return {
      reservasi: new Reservasi(),
      namaDokter: null,
      namaPoli: null
    };
  },
  props: {
    modalName: { type: String },
    tableName: { type: String },
    modalData: { type: Object }
  },
  filters: {
    formatTelepon: function(data){
        if(data != null){
            return '0'+data.substr(2,data.length-1);
        } else {
            return '';
        }
    },
    formatDate: function (data) {
      return moment(data).format("D MMMM YYYY");
    },
    formatSchedule: function (date, time) {
      var estimateTime = "";
      if (time) {
        estimateTime = " pukul " + time.substr(0, 5);
      }
      return moment(date).format("dddd, D MMMM YYYY") + estimateTime;
    }
  },
  methods: {
    setDetail: function (data) {
      this.reservasi = data;
      this.namaDokter = data.jadwal.dokter.nama;
      this.namaPoli = data.jadwal.dokter.tujuanRujukan.nama;
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.setDetail(this.modalData);
    }
  }
};
</script>
